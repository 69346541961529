import { Input } from '../../../../components/Input';
import { useForm } from 'react-hook-form';
import { Box } from '../../Box';
import { AddressFormContent, InputContainer } from '../AddressForm/style';
import { useRegister } from '../../../../hooks/useRegister';
import { mfaNewAccountVerify, sendNewAccountMfa } from '../../../../services/mfa.service';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export const MfaForm = () => {
  const { setStep, postMfaData, companyDataPj } = useRegister();
  const [otp, setOtp] = useState('');
  const [resendTimer, setResendTimer] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const handleBack = () => {
    setStep(1);
  };

  const handleMfa = async (phoneNumber) => {
    await sendNewAccountMfa(phoneNumber);
    setResendTimer(30); 
  };

  const handleMfaVerify = async () => {
    const response = await mfaNewAccountVerify(companyDataPj.phone, otp);

    if (response.ok) {
      toast.success('Código verificado com sucesso.');
      postMfaData();
      return;
    }
    if (otp.length === 6) {
      toast.error('Código de verificação inválido.');
      return;
    }
  };

  useEffect(() => {
    if (resendTimer > 0) {
      const timerId = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [resendTimer]);

  return (
    <Box
      handleSubmit={handleSubmit}
      submitEvent={() => handleMfaVerify()}
      title="Verificação de número de telefone"
      description="Por favor, insira o código enviado por SMS para confirmar o número de telefone informado."
      buttonOne="Voltar"
      buttonTwo="Prosseguir"
      marginLeft="m"
      handleBack={handleBack}
    >
      <AddressFormContent>
        <InputContainer>
          <Input
            type="text"
            label="Código de Verificação"
            placeholder="000000"
            error={errors.otp && errors.otp.message}
            isRequired
            register={register('otp', {
              required: true,
              onChange: (e) => setOtp(e.target.value),
            })}
          />

          <button
            style={{ marginTop: '34px' }}
            type="button"
            onClick={() => handleMfa(companyDataPj.phone)}
            disabled={resendTimer > 0}
          >
            {resendTimer > 0 ? `Reenviar em ${resendTimer}s` : 'Reenviar Código'}
          </button>

        </InputContainer>
      </AddressFormContent>
    </Box>
  );
};
