import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import {
  EntryContainer,
  EntryFilter,
  EntryHeader,
  EntryList,
  FilterButton,
  FilterItem,
  LoaderDiv,
  EntryMessage,
  EntryMonth,
  EntryTextArea,
  EntryCancel,
  ContentContainer,
  CardContainer,
  IconContainer,
  EntryCards,
  ButtonDiv,
  EntryEmit,
  Div,
  EntryImages,
  FormsItens,
  FormsButtons,
  ClientList,
  NameList,
  ErrorMessage,
  BankDataEntrys,
  InputArea,
  InputAreaIcon,
  FilterItemMfa,
  Label,
  Preview,
  EntryScrollValid,
  BalanceEntry,
  CancelEntry,
  ClientCell,
  ClientRow,
  ClientName,
  ClienTaxNumber,
  EntryCancelUpdate,
  FilterButtonUpdate,
  FormsButtonsUpdate,
  NotFoundMessage,
  ActionButton,
  MaskedInput,
  SelectClientButton,
  EntryScroll_,
  EntryValue_,
  EntryRadio_,
} from './style';
import { Input as InputWithIcon } from '../Input';
import { PageHeader } from '../page-header';
import { BoxModal } from '../boxModal';
import { getPixInfo, listContacts } from '../../services/pix.service';
import { TbCoin, TbKey, TbShare } from 'react-icons/tb';
import { useEcheck } from '../../hooks/useEcheck';
import { useClient } from '../../hooks/useClient';
import frame from '../E-check/img/Frame.svg';
import bank from '../E-check/img/bank.jpg';
import { validaCpf } from '../../services/pix.service';
import { toast } from 'react-toastify';
import { Message } from './style';
import IntlCurrencyInput from 'react-intl-currency-input';
import { useAccount } from '../../hooks/useAccount';
import { useAuth } from '../../hooks/useAuth';
import { identificarTipoChavePix } from '../../services/pix.service';
import { HiOutlineSearch } from 'react-icons/hi';
import { TbTrash } from 'react-icons/tb';
import { TbEdit } from 'react-icons/tb';
import { BsThreeDots } from 'react-icons/bs';
import { FaGreaterThan } from 'react-icons/fa6';
import { FaCircleExclamation } from 'react-icons/fa6';
import { set } from 'react-hook-form';

export default function () {
  const [contacts, setContacts] = useState();
  const [readOnly, setReadOnly] = useState(true);
  const [updateBtn, setUpdateBtn] = useState('Editar favorecido');
  const [secondaryBtn, setSecondaryBtn] = useState('Excluir');
  const [Open, setShow] = useState(false);
  const [isOpen, setShowModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [terceiroModal, setTerceiroModal] = useState(false);
  const [titleName, setTitleName] = useState();
  const [quartoModal, setQuartoModal] = useState(false);
  const [paidEcheckAlert, setPaidEcheckAlert] = useState(false);
  const [currentLayoutId, setCurrentLayoutId] = useState(1);
  const [modalPaidEcheck, setModalPaidEcheck] = useState(false);
  const [forms, setForms] = useState(false);
  const [query, setQuery] = useState('');
  const [_query, set_Query] = useState('');
  const [cancelModal, setCancelModal] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [valueError, setValueError] = useState();
  const [showBank, setShowBank] = useState(false);
  const [showMfa, setShowMfa] = useState(false);
  const [clients, setClients] = useState();
  const [modalClient, setModalClient] = useState(false);
  const [identifier, setIdentifier] = useState('');
  const { avaliableBalance, getBalance } = useAccount();
  const [link, setLink] = useState('');
  const [updateModal, setUpdateModal] = useState(false);
  const [allClients, setAllClients] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [echeckLayouts, setEcheckLayouts] = useState([]);
  const [preview, setPreview] = useState(echeckLayouts[0]);
  const { user } = useAuth();
  const [echeckClient, setEcheckClient] = useState({
    ReceiverName: '',
    ReceiverTaxNumber: '',
    ReceiverBank: '',
    ReceiverBankName: '',
  });
  const [selectedClient, setSelectedClient] = useState({
    id: '',
    name: '',
    phoneToSend: '',
    mailToSend: '',
    pixKey: '',
    taxNumber: '',
    bank: '',
  });
  const [inputs, setInputs] = useState({
    pixKey: '',
    value: 0,
    description: '',
    layoutId: 1,
  });
  const {
    createEcheque,
    fetchEcheckLayouts,
    fetchEcheck,
    validateTaxNumber,
    sendMfa,
    setEcheckData,
    setInputOTP,
    MfaAuthenticate,
  } = useEcheck();
  const {
    createClient,
    setFormData,
    error,
    setError,
    formData,
    fetchClient,
    client,
    updateClient,
    deleteClient,
    setClientData,
    clientData,
    isCreatingClient,
    setIsCreatingclient,
  } = useClient();
  function formatValueToMonetary(value) {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      return parsedValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
      });
    }
  }

  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  useEffect(() => {
    ListClients();
  }, []);

  async function ListClients() {
    const response = await fetchClient();
    setClients(response.data.slice(0, 6));
    setAllClients(response.data);
  }

  const handleRadioChange = (event, imageSrc) => {
    setPreview(imageSrc);
    setCurrentLayoutId(event.target.value);
    const selectedLayout = echeckLayouts.find(
      (layout) => layout.id == parseInt(event.target.value)
    );
    setInputs({
      ...inputs,
      layoutId: event.target.value,
      value: inputs.value + parseFloat(selectedLayout.value),
    });
    setEcheckData({
      clientId: selectedClient.id.toString(),
      pixKey: inputs.pixKey,
      value: inputs.value,
      description: inputs.description,
      layoutId: event.target.value || 1,
    });
  };

  const handleValueInputOnChange = (event, value, maskedValue) => {
    event.preventDefault();
    setInputs({ ...inputs, value });
  };

  const keyTypeIdentifier = (chave) => {
    const regexCNPJ = /^(\d{14}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/;
    const regexTelefone = /^(\+\d{2}(\d{2}|\d{3})\d{8,9}|\d{11,12})$/;
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (validaCpf(chave)) {
      return 'CPF';
    } else if (regexCNPJ.test(chave)) {
      return 'CNPJ';
    } else if (regexEmail.test(chave)) {
      return 'Email';
    } else if (regexTelefone.test(chave)) {
      return 'Telefone';
    } else {
      return 'Chave aleatória';
    }
  };

  const handleInputChange = async (event) => {
    const value = event.target.value.toLowerCase();
    setQuery(value);

    if (value) {
      const results = client.data.filter((user) =>
        user.name.toLowerCase().includes(value)
      );
      if (results.length > 4) {
        setFilteredUsers(results.slice(0, 4));
      } else {
        setFilteredUsers(results);
      }
    } else {
      setFilteredUsers([]);
    }
  };

  const handleInputChangeClients = async (val) => {
    const value = val.toLowerCase();
    set_Query(value);

    if (value) {
      const results = client.data.filter((user) =>
        user.name.toLowerCase().includes(value)
      );
      setAllClients(results);
    } else {
      setAllClients(client.data);
    }
  };

  const companyKey = process.env.REACT_APP_COMPANY_KEY;

  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const handleClick = async () => {
    await sendEcheckConfirmation();
    setIsDisabled(true);
    setCountdown(40);

    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(interval);
          setIsDisabled(false);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  function handleCloseModal() {
    setInputs({
      pixKey: '',
      value: 0,
      description: '',
      layoutId: '',
    });
    setShowBank(false);
    setShow(false);
    setShowModal(false);
    setSecondModal(false);
    setTerceiroModal(false);
    setQuartoModal(false);
    setShowMfa(false);
    setCancelModal(false);
    setSelectedClient({});
  }

  /*
  async function handle2FA() {
    setEcheckData({
      clientId: selectedClient.id,
      pixKey: inputs.pixKey,
      value: inputs.value,
      description: inputs.description,
      layoutId: inputs.layoutId,
    });
    await createEcheque();
    openQuartoModal();
  }
*/
  async function sendEcheckConfirmation() {
    const response = await sendMfa();
    // setToken2FA(response.data.data);
  }

  useEffect(() => {
    if (user.account) {
      getBalance();
    }
  }, [user]);

  useEffect(() => {
    fetchEcheck();
    fetchEcheckLayouts();
    fetchClient();
  }, []);

  const getContacts = async () => {
    const contacts = await listContacts();
    setContacts(contacts.data);
  };

  useEffect(() => {
    getContacts();
  }, []);

  const pages = () => {
    const pagesArr = [
      {
        name: 'e-Check',
        link: '',
      },
    ];

    return pagesArr;
  };

  function open() {
    setShow(true);
  }
  function cancel() {
    setShowBank(false);
    setShow(false);
  }

  function openModal() {
    setShowBank(false);
    setShowModal(true);
    setShow(false);
  }

  async function isPixKeyValid(pixKey) {
    let isValid = true;
    try {
      await getPixInfo(pixKey, identificarTipoChavePix(pixKey));
    } catch (err) {
      setIsCreatingclient(false);
      isValid = false;
    }
    return isValid;
  }

  async function createdClient(pixKey) {
    try {
      setIsCreatingclient(true);
      const isValid = await isPixKeyValid(pixKey);
      if (!isValid) {
        setError('Chave Pix inválida');
        return;
      }
      await createClient();
      setTimeout(async () => {
        const response = await fetchClient();
        setForms(false);
        setClients(response.data.slice(0, 6));
        setAllClients(response.data);
      }, 70);
      setSelectedClient({});
      setIsCreatingclient(false);
    } catch (err) {
      setIsCreatingclient(false);
      toast.error('Não foi possível criar novo contato');
    }
  }

  /*
  function openSecondModal() {

    if(!inputs.value) {
      setValueError('Campo obrigatório');
    } else if (inputs.value <= 0) {
      setValueError('Valor inválido');
    } else {
      setSecondModal(true);
      setShowModal(false);
    }
  }
  */
  async function openSegundoModal() {
    const hasBalance = await validatebalance();
    if (!inputs.value) {
      setValueError('Campo obrigatório');
    } else if (inputs.value <= 0) {
      setValueError('Valor inválido');
    } else if (hasBalance === false) {
      setValueError('Saldo insuficiente');
    } else {
      setEcheckData({
        clientId: selectedClient.id.toString(),
        pixKey: inputs.pixKey,
        value: inputs.value,
        description: inputs.description,
        layoutId: inputs.layoutId,
      });
      const Layouts = await fetchEcheckLayouts();
      Layouts.data.map((layout) => {
        layout.layout = layout.layout.replace('{{COMPANY_KEY}}', companyKey);
      });
      setPreview(Layouts.data[0].layout);
      setEcheckLayouts(Layouts.data);
      setTimeout(() => {
        setSecondModal(true);
        setShowModal(false);
      }),
        500;
    }
  }
  async function openQuartoModal() {
    setQuartoModal(true);
    setShowMfa(false);
  }

  async function openTerceiroModal() {
    setTerceiroModal(true);
    setSecondModal(false);
    setModalPaidEcheck(false);
  }

  async function openLayoutConfirmatiom() {
    if (currentLayoutId == 1) {
     openTerceiroModal();
    } else {
      setModalPaidEcheck(true);
    }
  }

  function openForms() {
    setForms(true);
    setError('');
  }

  function openBankData() {
    setShowBank(true);
  }

  async function OpenMfa() {
    await sendEcheckConfirmation();
    setShowMfa(true);
    setTerceiroModal(false);
  }

  async function validatebalance() {
    if (inputs.value > parseFloat(avaliableBalance)) {
      return false;
    } else {
      return true;
    }
  }

  async function handle2FA() {
    const response = await MfaAuthenticate();
    if (response.data.message !== '2FA realizado com sucesso') {
      toast.error(
        'Preencha o campo MFA corretamente para finalizar a transação!'
      );
    } else {
      const response = await createEcheque();
      setIdentifier(response.data.data);
      setLink(response.data.link);
      openQuartoModal();
    }
  }

  async function copyLink() {
    navigator.clipboard.writeText(link);
    toast.success('Link copiado com sucesso!');
  }

  async function ValidatePixKey(clientId, pixKey) {
    if (showBank) {
      openModal();
    } else {
      const responseInput = await validateTaxNumber(clientId, pixKey);
      if (!responseInput.data.data.ReceiverTaxNumber) {
        toast.error(responseInput.message);
        return;
      }
      setEcheckClient({
        ReceiverName: responseInput.data.data.ReceiverName,
        ReceiverTaxNumber: responseInput.data.data.ReceiverTaxNumber,
        ReceiverBank: responseInput.data.data.ReceiverBank,
        ReceiverBankName: responseInput.data.data.ReceiverBankName,
      });
      toast.success(responseInput.message);
      openBankData();
    }
  }
  const handleUserClick = async (user) => {
    setSelectedClient({
      id: user.id,
      name: user.name,
      phoneToSend: user.phoneToSend,
      mailToSend: user.mailToSend,
      pixKey: user.pixKey,
      taxNumber: user.taxNumber,
      bank: user.bank,
    });
    open();
  };

  const clickClient = (client) => {
    setSelectedClient(client);
  };

  const setClient = () => {
    if (selectedClient.id) {
      setModalClient(false);
      open();
    }
  };

  async function updatedClient(user) {
    setUpdateModal(true);
    setModalClient(false);
    setClientData(user);
    setError('');
    setTitleName(user.name);
    setSelectedClient({
      id: user.id,
      name: user.name,
      phoneToSend: user.phoneToSend,
      mailToSend: user.mailToSend,
      pixKey: user.pixKey,
      taxNumber: user.taxNumber,
      bank: user.bank,
    });
  }

  function closeEditModal() {
    setUpdateModal(false);
    setUpdateBtn('Editar favorecido');
    setSecondaryBtn('Excluir');
    setReadOnly(true);
    setSelectedClient({});
  }

  async function excluirCliente(id) {
    if (secondaryBtn === 'Cancelar') {
      setReadOnly(true);
      setUpdateBtn('Editar favorecido');
      setSecondaryBtn('Excluir');
    } else {
      setSelectedClient({});
      setUpdateModal(false);
      setUpdateBtn('Editar favorecido');
      setReadOnly(true);
      await deleteClient(id);
      setTimeout(async () => {
        const response = await fetchClient();
        setClients(response.data.slice(0, 6));
        setAllClients(response.data);
        setConfirmationModal(false);
        setModalClient(true);
      }, 70);
    }
  }

  function cancelEventEditModal() {
    if (secondaryBtn === 'Cancelar') {
      setReadOnly(true);
      setUpdateBtn('Editar favorecido');
      setSecondaryBtn('Excluir');
    } else {
      setConfirmationModal(true);
    }
  }

  async function editClient() {
    if (updateBtn === 'Salvar') {
      handleUpDateClient();
      setUpdateBtn('Editar favorecido');
      setSecondaryBtn('Excluir');
    } else {
      setReadOnly(false);
      setUpdateBtn('Salvar');
      setSecondaryBtn('Cancelar');
    }
  }

  async function handleUpDateClient() {
    try {
      const response = await getPixInfo(
        selectedClient.pixKey,
        identificarTipoChavePix(selectedClient.pixKey)
      );
      if (clientData.taxNumber !== response.data.data.ReceiverTaxNumber) {
        toast.error('Chave nao corresponde a titularidade do Contato');
      } else {
        await updateClient(selectedClient);
        setTimeout(async () => {
          const response = await fetchClient();
          setClients(response.data.slice(0, 6));
          setAllClients(response.data);
          setModalClient(true);
        }, 70);
      }
    } catch (err) {
      setError('Chave Pix inválida');
      return;
    }
  }

  return (
    <EntryContainer className="col-md-10">
      <EntryHeader>
        <div className="col-md-12">
          <PageHeader title="e-Check" pages={pages()} />
        </div>
      </EntryHeader>

      <EntryFilter className="col-md-12 gap-2">
        <div className="col-md-5">
          <label htmlFor="searchClient">Selecione um Contato</label>
          <input
            onChange={(e) => handleInputChange(e)}
            placeholder="pesquisar pelo nome, CPF ou CNPJ"
            className="form-control w-100"
            id="searchClient"
            value={query}
            name="searchClient"
            type="text"
          />
        </div>
        <EntryCancel onClick={() => setModalClient(true)}>Contatos</EntryCancel>
        <FilterButton onClick={openForms}>Novo Contato</FilterButton>
      </EntryFilter>
      <ClientList className="col-md-12 gap-2">
        {filteredUsers.map((user) => (
          <NameList key={user.id}>
            <ClientCell>
              <a onClick={() => handleUserClick(user)}>
                {' '}
                {user.name}, {user.phoneToSend}, {user.mailToSend}
              </a>
            </ClientCell>
          </NameList>
        ))}
      </ClientList>
      <EntryList className="col-md-12">
        <h2 className="mt-5 mb-4">Contatos</h2>
        <EntryCards>
          {clients &&
            clients.map((item, index) => {
              return (
                <CardContainer key={index}>
                  <a onClick={() => handleUserClick(item)}>
                    <IconContainer>
                      {item.name.split(' ').length > 1
                        ? item.name.split(' ')[0].split('')[0] +
                          item.name.split(' ')[1].split('')[0]
                        : item.name.split(' ')[0].split('')[0]}
                    </IconContainer>
                    <ContentContainer>
                      <p>{item.name}</p>
                    </ContentContainer>
                  </a>
                </CardContainer>
              );
            })}
        </EntryCards>
      </EntryList>
      {cancelModal && (
        <ReactModal
          isOpen={cancelModal}
          className={'change-modal'}
          overlayClassName={'overlay-pix-modal'}
        >
          <BoxModal
            title="Cancelar e-Check"
            close={() => setCancelModal(false)}
            maxWidth={500}
          >
            <FilterItem>
              <CancelEntry>
                Tem certeza que deseja cancelar a emissão do
              </CancelEntry>
              <CancelEntry>e-Check?</CancelEntry>
            </FilterItem>
            <LoaderDiv>
              <EntryCancel onClick={() => setCancelModal(false)}>
                Não
              </EntryCancel>
              <FilterButton onClick={() => handleCloseModal()}>
                Sim
              </FilterButton>
            </LoaderDiv>
          </BoxModal>
        </ReactModal>
      )}
      {confirmationModal && (
        <ReactModal
          isOpen={confirmationModal}
          className={'change-modal'}
          overlayClassName={'overlay-pix-modal'}
        >
          <BoxModal
            title={'Excluir favorecido'}
            close={() => setConfirmationModal(false)}
            maxWidth={500}
          >
            <CancelEntry>
              Tem certeza que deseja excluir {titleName}?
            </CancelEntry>
            <LoaderDiv>
              <EntryCancel onClick={() => setConfirmationModal(false)}>
                Não
              </EntryCancel>
              <FilterButton onClick={() => excluirCliente(selectedClient.id)}>
                Sim
              </FilterButton>
            </LoaderDiv>
          </BoxModal>
        </ReactModal>
      )}
      {paidEcheckAlert && (
        <ReactModal
          isOpen={paidEcheckAlert}
          className={'change-modal'}
          overlayClassName={'overlay-pix-modal'}
        >
          <BoxModal maxWidth={400} close={() => setPaidEcheckAlert(false)}>

            <CancelEntry>
              Ao adquirir este tema, você entrará nessa luta conosco.<br/> <br/>100% do
              valor arrecadado será destinado ao apoio à causa do autismo. O
              valor será debitado da sua conta após a compra. Junte-se a nós e
              faça a diferença!
            </CancelEntry>
         <LoaderDiv>
           <EntryCancel onClick={() => setPaidEcheckAlert(false)}>
             Ok
           </EntryCancel>
         </LoaderDiv>
           
          </BoxModal>
        </ReactModal>
      )}
       {modalPaidEcheck && (
        <ReactModal
          isOpen={modalPaidEcheck}
          className={'change-modal'}
          overlayClassName={'overlay-pix-modal'}
        >
          <BoxModal maxWidth={450} close={() => setModalPaidEcheck(false)}>
            <img width={385} src={preview} alt="cheque" />
            <CancelEntry>
              Ao adquirir este tema, você entrará nessa luta conosco.<br/> <br/>100% do
              valor arrecadado será destinado ao apoio à causa do autismo. O
              valor será debitado da sua conta após a compra. Junte-se a nós e
              faça a diferença!
            </CancelEntry>
            <LoaderDiv>
              <EntryCancel onClick={() => setModalPaidEcheck(false)}>
                Cancelar
              </EntryCancel>
              <FilterButton onClick={() => openTerceiroModal()}>
                Confirmar
              </FilterButton>
            </LoaderDiv>
          </BoxModal>
        </ReactModal>
      )}
      {modalClient && (
        <ReactModal
          isOpen={modalClient}
          className={'change-modal'}
          overlayClassName={'overlay-pix-modal'}
        >
          <BoxModal
            title={
              <div className="d-flex justify-content-between">
                <p>Lista de Favorecidos</p>
                <div className="col-md-4">
                  <SelectClientButton
                    onClick={() => {
                      setClient();
                    }}
                  >
                    Selecionar favorecido <FaGreaterThan size={12} />
                  </SelectClientButton>
                </div>
              </div>
            }
            close={() => setModalClient(false)}
            maxWidth={650}
          >
            <FilterItem>
              <div className="mb-4">
                <InputWithIcon
                  icon={<HiOutlineSearch size={18} />}
                  onChange={handleInputChangeClients}
                  placeholder="Procurar"
                  value={_query}
                />
              </div>
              <ClientRow>
                {allClients.length === 0 ? (
                  <NotFoundMessage>
                    Nenhum favorecido encontrado
                  </NotFoundMessage>
                ) : (
                  allClients.map((item, index) => {
                    return (
                      <div key={item.id} onClick={() => clickClient(item)}>
                        <ClientCell selected={item.id == selectedClient.id}>
                          <div className="d-flex align-itens-center col-md-5">
                            <IconContainer>
                              {item.name.split(' ').length > 1
                                ? item.name.split(' ')[0].split('')[0] +
                                  item.name.split(' ')[1].split('')[0]
                                : item.name.split(' ')[0].split('')[0]}
                            </IconContainer>
                            <ClientName>{item.name}</ClientName>
                          </div>
                          <ClienTaxNumber className="col-md-4">
                            {item.taxNumber.length === 14 ? 'CNPJ' : 'CPF'}{' '}
                            {item.taxNumber}
                          </ClienTaxNumber>
                          <ActionButton
                            className="col-md-1"
                            onClick={() => updatedClient(item)}
                          >
                            <BsThreeDots />
                          </ActionButton>
                        </ClientCell>
                      </div>
                    );
                  })
                )}
              </ClientRow>
            </FilterItem>
          </BoxModal>
        </ReactModal>
      )}
      <ReactModal
        isOpen={updateModal}
        className={'change-modal'}
        overlayClassName={'overlay-pix-modal'}
      >
        <BoxModal
          title={titleName}
          close={() => closeEditModal()}
          maxWidth={640}
        >
          <FormsItens>
            <div className="col-md-11">
              <FilterItem>
                {error && <ErrorMessage color="red">*{error}</ErrorMessage>}
                <label>Nome</label>
                <EntryMonth
                  type="text"
                  readOnly={readOnly}
                  value={selectedClient.name}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      name: e.target.value,
                    })
                  }
                />
              </FilterItem>
              <FilterItem>
                <label>Telefone</label>
                <MaskedInput
                  type="text"
                  mask={'(99)99999-9999'}
                  readOnly={readOnly}
                  value={selectedClient.phoneToSend}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      phoneToSend: e.target.value,
                    })
                  }
                />
              </FilterItem>
              <FilterItem>
                <label>Email</label>
                <EntryMonth
                  type="text"
                  readOnly={readOnly}
                  value={selectedClient.mailToSend}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      mailToSend: e.target.value,
                    })
                  }
                />
              </FilterItem>
              <FilterItem>
                <label>Chave pix</label>
                <EntryMonth
                  type="text"
                  readOnly={readOnly}
                  value={selectedClient.pixKey}
                  onChange={(e) =>
                    setSelectedClient({
                      ...selectedClient,
                      pixKey: e.target.value,
                    })
                  }
                />
              </FilterItem>
            </div>
          </FormsItens>
          <FormsButtonsUpdate>
            <EntryCancelUpdate onClick={() => cancelEventEditModal()}>
              {secondaryBtn === 'Excluir' ? (
                <>
                  <TbTrash size={18} /> {secondaryBtn}
                </>
              ) : (
                secondaryBtn
              )}
            </EntryCancelUpdate>
            <FilterButtonUpdate onClick={() => editClient()}>
              {updateBtn === 'Editar favorecido' ? (
                <>
                  <TbEdit size={18} /> {updateBtn}
                </>
              ) : (
                updateBtn
              )}
            </FilterButtonUpdate>
          </FormsButtonsUpdate>
        </BoxModal>
      </ReactModal>
      <ReactModal
        isOpen={forms}
        className={'change-modal'}
        overlayClassName={'overlay-pix-modal'}
      >
        <BoxModal
          title="Formulário de e-Check"
          close={() => setForms(false)}
          maxWidth={520}
        >
          <FormsItens>
            <div className="col-md-11">
              <FilterItem>
                {error && <ErrorMessage color="red">*{error}</ErrorMessage>}
                <label>Nome do contato</label>
                <EntryMonth
                  type="text"
                  placeholder="Nome do contato"
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </FilterItem>
              <FilterItem>
                <label>Chave pix do contato</label>
                <EntryMonth
                  type="text"
                  placeholder="Chave pix do contato"
                  onChange={(e) =>
                    setFormData({ ...formData, pixKey: e.target.value })
                  }
                />
              </FilterItem>
              <FilterItem>
                <label>Telefone do contato</label>
                <MaskedInput
                  mask={'(99)99999-9999'}
                  placeholder="Telefone do contato"
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                />
              </FilterItem>
              <FilterItem>
                <label>Email do contato</label>
                <EntryMonth
                  type="text"
                  placeholder="Email do contato"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </FilterItem>
            </div>
          </FormsItens>
          <FormsButtons>
            <EntryCancel onClick={() => setForms(false)}>Cancelar</EntryCancel>
            <FilterButton
              onClick={() =>
                isCreatingClient ? '' : createdClient(formData.pixKey)
              }
              disabled={isCreatingClient}
            >
              {isCreatingClient ? 'Salvando...' : 'Salvar'}
            </FilterButton>
          </FormsButtons>
        </BoxModal>
      </ReactModal>
      <ReactModal
        isOpen={Open}
        className={'change-modal'}
        overlayClassName={'overlay-pix-modal'}
      >
        <BoxModal
          title="Confirmação chave Pix"
          close={() => setCancelModal(true)}
          maxWidth={800}
        >
          <FilterItem>
            <LoaderDiv>
              <section>
                <div>nome</div>
                <strong>{selectedClient.name}</strong>
              </section>

              <section>
                <div>CPF do Favorecido</div>
                <strong>{selectedClient.taxNumber}</strong>
              </section>
            </LoaderDiv>
            <div>
              <div className="col-md-7">
                <FilterItem>
                  <label>Chave Pix:*</label>
                  <InputArea>
                    <InputAreaIcon className="col-md-1">
                      <TbKey size={18} />
                    </InputAreaIcon>
                    <EntryMonth
                      type="text"
                      value={inputs.pixKey}
                      placeholder="CPF, CNPJ, Celular ou Chave Aleatória"
                      onChange={(e) =>
                        setInputs({ ...inputs, pixKey: e.target.value })
                      }
                    />
                  </InputArea>
                </FilterItem>
              </div>
            </div>
          </FilterItem>
          {showBank && (
            <FilterItem>
              <p>Dados do Contato</p>
              <LoaderDiv>
                <BankDataEntrys>
                  <img width={60} src={frame} alt="cheque" />
                  <section>
                    <div>nome</div>
                    <strong>{echeckClient.ReceiverName}</strong>
                  </section>
                </BankDataEntrys>
                <BankDataEntrys>
                  <img width={60} src={bank} alt="cheque" />
                  <section>
                    <div>Instituição de destino</div>
                    <strong>
                      {echeckClient.ReceiverBank} -{' '}
                      {echeckClient.ReceiverBankName}
                    </strong>
                  </section>
                </BankDataEntrys>
              </LoaderDiv>
            </FilterItem>
          )}
          <LoaderDiv>
            <EntryCancel onClick={() => setCancelModal(true)}>
              Cancelar
            </EntryCancel>
            <FilterButton
              onClick={() => ValidatePixKey(selectedClient.id, inputs.pixKey)}
            >
              {showBank ? 'Confirmar' : 'Prosseguir'}
            </FilterButton>
          </LoaderDiv>
        </BoxModal>
      </ReactModal>
      <ReactModal
        isOpen={isOpen}
        className={'change-modal'}
        overlayClassName={'overlay-pix-modal'}
      >
        <BoxModal
          title="Dados do recebedor"
          close={() => setCancelModal(true)}
          maxWidth={800}
        >
          <FilterItem>
            <LoaderDiv>
              <section>
                <div>nome</div>
                <strong>{echeckClient.ReceiverName}</strong>
              </section>

              <section>
                <div>Instituição de Destino</div>
                <strong>
                  {echeckClient.ReceiverBank} - {echeckClient.ReceiverBankName}
                </strong>
              </section>

              <section>
                <div>CPF do Favorecido</div>
                <strong>{echeckClient.ReceiverTaxNumber}</strong>
              </section>
            </LoaderDiv>
            <div>
              <div className="col-md-7">
                <FilterItem className="form-group">
                  {valueError && <ErrorMessage>*{valueError}</ErrorMessage>}
                  <label>Valor:*</label>
                  <InputArea>
                    <InputAreaIcon className="col-md-1">
                      <TbCoin color="#000" size={18} />
                    </InputAreaIcon>
                    <div className="col-md-11">
                      <IntlCurrencyInput
                        currency="BRL"
                        config={currencyConfig}
                        onChange={handleValueInputOnChange}
                        value={inputs.value}
                      />
                    </div>
                  </InputArea>
                </FilterItem>
                <BalanceEntry>Saldo atual: R${avaliableBalance}</BalanceEntry>
              </div>
              <FilterItem className="col-md-10">
                <label>Descrição: (opcional)</label>
                <EntryTextArea
                  onChange={(e) =>
                    setInputs({ ...inputs, description: e.target.value })
                  }
                  maxLength={80}
                />
              </FilterItem>
            </div>
          </FilterItem>
          <LoaderDiv>
            <EntryCancel onClick={() => setCancelModal(true)}>
              Cancelar
            </EntryCancel>
            <FilterButton
              onClick={openSegundoModal}
              className="btn btn-primary-button"
            >
              Prosseguir
            </FilterButton>
          </LoaderDiv>
        </BoxModal>
      </ReactModal>
      <ReactModal
        isOpen={secondModal}
        className={'change-modal'}
        overlayClassName={'overlay-pix-modal'}
      >
        <BoxModal
          title="Personalize seu e-Check"
          close={() => setCancelModal(true)}
          maxWidth={700}
        >
          <FilterItem>
            <Div>
              <img width={370} src={preview} alt="cheque" />
              <Preview>Preview</Preview>
            </Div>
            <p>Escolha seu background</p>
            <div>{/*  <Input placeholder="Search" /> */}</div>
            {echeckLayouts ? (
              <FilterItem>
                {echeckLayouts.map((layout, index) => (
                  <EntryScrollValid key={layout.id}>
                    <EntryScroll_>
                      <img width={100} src={layout.layout} alt="cheque" />

                      {layout.identifier !== 'canal' ? (
                        <>
                          <p className="col-md-4">{layout.identifier}</p>
                          <FaCircleExclamation
                            onClick={() => setPaidEcheckAlert(true)}
                            className="col-md-1"
                            size={10}
                            style={{
                              position: 'absolute',
                              marginLeft: '150px',
                              marginBottom: '30px',
                              color: 'var(--text-primary)',
                            }}
                          />
                        </>
                      ) : (
                        <p className="col-md-4">{layout.identifier}</p>
                      )}
                      <EntryValue_ className="col-md-2">
                        {layout.value == 0
                          ? 'Grátis'
                          : formatValueToMonetary(layout.value)}
                      </EntryValue_>
                      <EntryRadio_
                        className="col-md-0"
                        type="radio"
                        defaultChecked={layout.identifier === 'canal'}
                        name="layout"
                        value={layout.id}
                        onChange={(e) => handleRadioChange(e, layout.layout)}
                      />
                    </EntryScroll_>
                  </EntryScrollValid>
                ))}
              </FilterItem>
            ) : (
              <LoaderDiv></LoaderDiv>
            )}
          </FilterItem>
          <ButtonDiv>
            <EntryCancel onClick={() => setCancelModal(true)}>
              Cancelar
            </EntryCancel>
            <FilterButton
              className="btn btn-primary-button"
              onClick={openLayoutConfirmatiom}
            >
              Prosseguir
            </FilterButton>
          </ButtonDiv>
        </BoxModal>
      </ReactModal>
      <ReactModal
        isOpen={terceiroModal}
        className={'change-modal'}
        overlayClassName={'overlay-pix-modal'}
      >
        <BoxModal
          title="Confirmar Emissão de e-Check"
          close={() => setCancelModal(true)}
          maxWidth={700}
        >
          <FilterItem>
            <LoaderDiv>
              <section>
                <div>nome</div>
                <strong>{echeckClient.ReceiverName}</strong>
              </section>

              <section>
                <div>Instituição de Destino</div>
                <strong>
                  {echeckClient.ReceiverBank} - {echeckClient.ReceiverBankName}
                </strong>
              </section>

              <section>
                <div>CPF do Favorecido</div>
                <strong>{echeckClient.ReceiverTaxNumber}</strong>
              </section>
            </LoaderDiv>
            <LoaderDiv>
              <section>
                <div>Valor</div>
                <strong>R$ {inputs.value.toFixed(2)}</strong>
              </section>

              <section>
                <div>Tipo</div>
                <strong>{keyTypeIdentifier(inputs.pixKey)}</strong>
              </section>

              <section>
                <div>Chave Pix</div>
                <strong>{inputs.pixKey}</strong>
              </section>
            </LoaderDiv>
            {inputs.description && (
              <LoaderDiv>
                <section>
                  <div>Descrição</div>
                  <strong>{inputs.description}</strong>
                </section>
              </LoaderDiv>
            )}
            <EntryImages>
              <p>Layout e-Check</p>

              <img width={500} src={preview} alt="cheque" />
            </EntryImages>
          </FilterItem>
          <EntryMessage>
            <button onClick={OpenMfa}>Emitir e-Check</button>
          </EntryMessage>
        </BoxModal>
      </ReactModal>
      <ReactModal
        isOpen={showMfa}
        className={'change-modal'}
        overlayClassName={'overlay-pix-modal'}
      >
        <BoxModal
          title="Confirmar Emissão de e-Check"
          close={() => setCancelModal(true)}
          maxWidth={700}
        >
          <FilterItem>
            <Message>
              Você receberá um codigo de confirmação via SMS para finalizar essa
              transação
            </Message>
            <div>
              <Label>Codigo de confirmação</Label>
              <EntryMonth
                className="col-md-4"
                type="text"
                placeholder="Digite o codigo de confirmação"
                onChange={(e) => setInputOTP(e.target.value)}
              />

              <FilterItemMfa>
                {isDisabled && (
                  <p>
                    Você pode solicitar um novo código em 0:{countdown}{' '}
                    segundos
                  </p>
                )}
                <button onClick={handleClick} disabled={isDisabled}>
                  Novo Código
                </button>
              </FilterItemMfa>
            </div>
          </FilterItem>
          <EntryMessage>
            <button onClick={handle2FA}>Emitir e-Check</button>
          </EntryMessage>
        </BoxModal>
      </ReactModal>
      <ReactModal
        isOpen={quartoModal}
        className={'change-modal'}
        overlayClassName={'overlay-pix-modal'}
      >
        <BoxModal
          title="e-Check emitido com sucesso!"
          close={() => setCancelModal(true)}
          maxWidth={700}
        >
          <FilterItem>
            <LoaderDiv>
              <section>
                <div>nome</div>
                <strong>{echeckClient.ReceiverName}</strong>
              </section>

              <section>
                <div>Instituição de Destino</div>
                <strong>
                  {echeckClient.ReceiverBank} - {echeckClient.ReceiverBankName}
                </strong>
              </section>

              <section>
                <div>CPF do Favorecido</div>
                <strong>{echeckClient.ReceiverTaxNumber}</strong>
              </section>
            </LoaderDiv>
            <LoaderDiv>
              <section>
                <div>Valor</div>
                <strong>R$ {inputs.value.toFixed(2)}</strong>
              </section>

              <section>
                <div>Chave Pix</div>
                <strong>{keyTypeIdentifier(inputs.pixKey)}</strong>
              </section>

              <section>
                <div>Chave Pix</div>
                <strong>{inputs.pixKey}</strong>
              </section>
            </LoaderDiv>
            {inputs.description && (
              <LoaderDiv>
                <section>
                  <div>Descrição</div>
                  <strong>{inputs.description}</strong>
                </section>
              </LoaderDiv>
            )}
          </FilterItem>
          <FilterItem>
            <EntryMessage>
              <button onClick={copyLink}>
                Compartilhar link do e-Check <TbShare size={16} />
              </button>
            </EntryMessage>
            <EntryEmit>
              <button onClick={() => setQuartoModal(false)}>
                Emitir novo e-Check
              </button>
            </EntryEmit>
          </FilterItem>
        </BoxModal>
      </ReactModal>
    </EntryContainer>
  );
}
