import React, { useEffect, useState } from 'react';

import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';

import '../transferir/Style.css';
import IntlCurrencyInput from 'react-intl-currency-input';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { css } from '@emotion/react';
import BeatLoader from 'react-spinners/BeatLoader';
import { banco_codigo } from '../../../assets/banco_codigo';
import ReactModal from 'react-modal';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { sendTedFitBank } from '../../../services/ted.service';
import { FiShare2 } from 'react-icons/fi';
import { Box } from '../../box';
import {
  formatCnpjCpf,
  priceFormatter,
  unformmattedTaxNumber,
} from '../../../utils/formatter';
import { apiV1 } from '../../../lib/axios';
import { useAuth } from '../../../hooks/useAuth';
import { getImage } from '../../../services/getAssets';
import { cnpjCpfValidator } from '../../../utils/taxNumberValidator';
import Select from 'react-select';
import { useTed } from '../../../hooks/useTed';
import Asterisk from '../../../shared/components/asterisk/asterisk';
import { useAccount } from '../../../hooks/useAccount';
import { BoxModal } from '../../boxModal';
import {
  EntryCancel,
  FilterButton,
  FilterItemMfaGeneralTed,
} from '../../E-check/style';
import { sendTransactionMfa } from '../../../services/mfa.service';

export default function () {
  const location = useLocation();
  const [valor, setValor] = useState(0);
  const [response, setResponse] = useState();
  const [loader, setLoader] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const [mfaCode, setMfaCode] = useState('');

  const [transferDateTime, setTransferDateTime] = useState(null);

  const { user, getProfile } = useAuth();
  const { avaliableBalance, getBalance } = useAccount();

  const {
    agencia,
    conta,
    digito,
    tipoConta,
    data,
    isFavorite,
    cpf,
    nome,
    banco,
    setAgencia,
    setConta,
    setDigito,
    setTipoConta,
    setisFavorite,
    setCpf,
    setNome,
    setBanco,
    alreadyFavorites,
  } = useTed();
  const navigate = useNavigate();

  const override = css`
    display: flex;
    margin: 0 auto;
    border-color: red;
  `;

  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const handleChange = (event, value, maskedValue) => {
    event.preventDefault();

    setValor(value);
  };

  const handleClick = async () => {
    await sendTransactionMfa();
    setIsDisabled(true);
    setCountdown(40);

    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(interval);
          setIsDisabled(false);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const confirm = async () => {
   
    const inputsValidos = await checkInputs();
    if (!inputsValidos) {
      setLoader(false);
      return;
    }

    await sendTransactionMfa();
    setIsOpenModal(true);
  };

  const checkpersonType = () => {
    let cleanCpf = cpf
      .replaceAll('.', '')
      .replaceAll('-', '')
      .replaceAll('/', '');

    if (!cnpjCpfValidator(cleanCpf)) {
      return null;
    }

    if (cleanCpf.length === 11) {
      return 'F';
    } else if (cleanCpf.length === 14) {
      return 'J';
    } else {
      return null;
    }
  };

  async function getComprovante(data) {
    await apiV1.post('/voucher/generate', data).then((response) => {
      const res = response.data.data;

      localStorage.removeItem('comprovante');
      localStorage.setItem('comprovante', res.base64);
    });
  }

  const checkInputs = async (data) => {
    if (tipoConta !== 0 && tipoConta !== 1) {
      toast.error('Por favor, selecione um tipo de conta!');
      return false;
    }
    if (valor > avaliableBalance) {
      toast.error('O valor excede o saldo disponível.');
      setLoader(false);
      return false;
    }
    if (!banco) {
      toast.error('Digite a instituição de destino.');
      setLoader(false);

      return false;
    }

    if (!nome) {
      toast.error('Digite o nome do favorecido.');
      setLoader(false);

      return false;
    }

    if (!agencia) {
      toast.error('Digite a agência.');
      setLoader(false);

      return false;
    }

    if (!conta) {
      toast.error('Digite a conta.');
      setLoader(false);

      return false;
    }

    if (!digito) {
      toast.error('Digite o digito.');
      setLoader(false);

      return false;
    }

    if (!valor) {
      toast.error('Digite o valor da transferência');
      setLoader(false);

      return false;
    }

    const tipoPessoa = checkpersonType();
    if (!tipoPessoa) {
      toast.error('Cpf ou Cnpj inválido!');
      setLoader(false);
      return;
    }

    return true;
  };

  const handleTedConfirmation = async (otp) => {
    const response = await sendMfaAuthenticate(otp);
    if (response.data.ok == true) {
      await sendTransfers();
      setMfaCode('');
    }
  };
  function handleErrorTed(response) {
    if (response?.error) {
      const errorMessage = response.message || 'Erro ao processar a TED';
      return { success: false, errorMessage };
    }
    return { success: true };
  }
  const today = new Date().toISOString().split('T')[0];
  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 31);
  const maxDateFormatted = maxDate.toISOString().split('T')[0];
  const [transferDate, setTransferDate] = useState(today);
  const handleDateChange = (e) => {
    setTransferDate(e.target.value);
  };
  async function sendTransfers(otp) {
    setLoader(true);
    if (!mfaCode) {
      toast.error('Digite o código MFA');
      setLoader(false);
      return;
    }

    setIsOpenModal(false);
    const dateParts = transferDate.split('-');
    const transferDateTime = new Date(
      dateParts[0],
      dateParts[1] - 1,
      dateParts[2],
      0,
      0,
      0
    );
    const body = {
      fromTaxNumber: localStorage.getItem('taxNumber'),
      toName: nome,
      toTaxNumber: unformmattedTaxNumber(cpf),
      toBank: banco,
      toBankBranch: agencia,
      toBankAccount: conta,
      toBankAccountDigit: digito,
      value: valor,
      rateValue: '0',
      transferDate: transferDateTime.toISOString(),
      type: tipoConta,
      isFavorite,
      otp,
    };

    try {
      const tedResponse = await sendTedFitBank(body);
      let data = tedResponse.data.data;
      if (tedResponse?.error) {
        const { success } = handleErrorTed(tedResponse);
        if (!success) {
          setLoader(false);
          return;
        }
      }
      toast.success('Transferência enviada!');
      await getComprovante({
        transferDate: transferDateTime.toISOString(),
        type: 'ted',
        receiverName: data.toName,
        receiverTaxNumber: data.toTaxNumber,
        receiverBank: `${data.toBank} - ${
          banco_codigo.find((item) => item.value === data.toBank)?.label
        }`,
        receiverBankBranch: data.toBankBranch,
        receiverBankAccount: data.toBankAccount,
        receiverBankAccountDigit: data.toBankAccountDigit,
        payerName: user.name,
        payerTaxNumber: user.taxNumber,
        payerBank: user.account.bank,
        payerBankBranch: user.account.bankBranch,
        payerBankAccount: user.account.bankAccount,
        payerBankAccountDigit: user.account.bankAccountDigit,
        identifier: tedResponse.data.identifier,
        value: valor,
        transactionId: data.id,
        url_logo: getImage('logo-preto.png'),
      });

      setResponse(data);
      clearInputs();
    } catch (err) {
      console.error('Erro ao enviar TED: ', err);
    } finally {
      setLoader(false);
      setMfaCode(null);
    }
  }

  async function clearInputs() {
    setValor(0);
    setAgencia('');
    setConta('');
    setDigito('');
    setCpf('');
    setNome('');
    setBanco('');
  }

  const toggleCheckBox = (e) => {
    setTipoConta(e.target.value);
  };
  function validarNome(input) {
    if (/\d/.test(input.target.value)) {
      input.target.value = input.target.value.replace(/\d/g, '');
    }
    setNome(input.target.value);
  }

  async function getInfos() {
    await getProfile();
    await getBalance();
  }

  useEffect(() => {
    getInfos();
  }, []);

  useEffect(() => {
    if (tipoConta === 0) {
      document.getElementById('CI').checked = false;
    } else if (tipoConta === 1) {
      document.getElementById('CC').checked = false;
    }
  }, [tipoConta]);

  useEffect(() => {
    if (location.state?.idTransfer) {
      apiV1.get(`/transfer/${location.state?.idTransfer}`).then((response) => {
        setAgencia(response.data.transfer.toBankBranch);
        setNome(response.data.transfer.toName);
        setCpf(response.data.transfer.toTaxNumber);
        setConta(response.data.transfer.toBankAccount);
        setDigito(response.data.transfer.toBankAccountDigit);
        setBanco(response.data.transfer.toBank);
      });
    }
  }, [location]);

  useEffect(() => {
    if (transferDate) {
      const dateParts = transferDate.split('-');
      const date = new Date(
        dateParts[0],
        dateParts[1] - 1,
        dateParts[2],
        0,
        0,
        0
      );
      setTransferDateTime(date);
    }
  }, [transferDate]);
  return (
    <>
      <div className="transferencia-ted">
        <div className="transferencia-ted-container col-md-10">
          <div className="header">
            <div className="col-xl-12">
              <label>
                <Link to={'/pagina-principal'}>
                  Pagina inicial
                  <MdKeyboardArrowRight size={20} />
                </Link>
                TED
              </label>
              <p>Nova Transferência</p>
            </div>
          </div>

          <div className="body">
            {!response ? (
              <div className="">
                <div className="input-row">
                  <div>
                    <label>
                      Valor da Transferência <Asterisk />
                    </label>
                    <div className="input-area">
                      <IntlCurrencyInput
                        currency="BRL"
                        config={currencyConfig}
                        onChange={handleChange}
                        value={valor}
                      />
                    </div>
                  </div>
                </div>

                <div className="input-row">
                  <div className="col-md-5">
                    <label>
                      Data de Transferência <Asterisk />
                    </label>
                    <div className="input-area">
                      <input
                        type="date"
                        value={transferDate}
                        onChange={handleDateChange}
                        min={today}
                        max={maxDateFormatted}
                      />
                    </div>
                  </div>

                  <div className="col-md-5">
                    <label>
                      Instituição de Destino <Asterisk />
                    </label>
                    <Select
                      className="bank-select"
                      options={banco_codigo}
                      value={{
                        value: banco,
                        label: banco_codigo.find((item) => item.value === banco)
                          ?.label,
                      }}
                      onChange={(e) => setBanco(e.value)}
                      placeholder="Selecione..."
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: 'none',
                        },
                      })}
                      styles={{
                        option: (styles) => {
                          return {
                            ...styles,
                            cursor: 'pointer',
                          };
                        },
                      }}
                    />
                  </div>
                </div>

                <h2 className="body-h2">
                  Dados de Destino <Asterisk />
                </h2>

                <div className="input-row">
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      value={0}
                      id="CC"
                      onChange={toggleCheckBox}
                      checked={tipoConta == 0}
                    />
                    <label>Conta Corrente</label>
                  </div>
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      value={1}
                      id="CI"
                      onChange={toggleCheckBox}
                      checked={tipoConta == 1}
                    />
                    <label>Conta Poupança</label>
                  </div>
                </div>

                <div className="input-row">
                  <div className="col-md-5">
                    <label>
                      CPF/CNPJ <Asterisk />
                    </label>
                    <div className="input-area">
                      <input
                        type="text"
                        maxLength={18}
                        value={cpf}
                        onChange={(e) => {
                          setCpf(
                            formatCnpjCpf(
                              e.target.value.replace(/[^0-9.]/g, '')
                            )
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <label>
                      Nome do Favorecido <Asterisk />
                    </label>
                    <div className="input-area">
                      <input type="text" value={nome} onInput={validarNome} />
                    </div>
                  </div>
                </div>

                <div className="input-row">
                  <div className="col-md-5">
                    <label>
                      Agência <Asterisk />
                    </label>
                    <div className="input-area">
                      <InputMask
                        mask="9999"
                        value={agencia}
                        onChange={(e) => {
                          setAgencia(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-5">
                    <label>
                      Conta <Asterisk />
                    </label>
                    <div className="d-flex">
                      <div className="input-area col-md-9">
                        <input
                          type="text"
                          maxLength={11}
                          value={conta}
                          onChange={(e) => {
                            setConta(
                              e.target.value
                                .replace(/[^0-9.]/g, '')
                                .replace(/(\..*?)\..*/g, '$1')
                            );
                          }}
                        />
                      </div>
                      <div className="col-md-1">
                        <p>-</p>
                      </div>
                      <div className="input-area col-md-2">
                        <InputMask
                          mask="9"
                          value={digito}
                          onChange={(e) => {
                            setDigito(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {!alreadyFavorites && (
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      onChange={() => setisFavorite(!isFavorite)}
                    />
                    <label>Salvar contato</label>
                  </div>
                )}

                <div className="button-area mt-5">
                  <button
                    className="confirm-button"
                    onClick={() => confirm()}
                    disabled={loader}
                  >
                    {loader ? (
                      <BeatLoader
                        color="var(--text-primary)"
                        loading={loader}
                        css={override}
                        size={20}
                      />
                    ) : (
                      'Realizar novo Ted'
                    )}
                  </button>

                  <button
                    className="cancel-button"
                    onClick={() => navigate('/pagina-ted')}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            ) : (
              <div className="receipt">
                <div className="resumo-transferencia">
                  <Box
                    icon="circleCheck"
                    title={
                      transferDateTime &&
                      new Date(transferDateTime).setHours(0, 0, 0, 0) ===
                        new Date().setHours(0, 0, 0, 0)
                        ? 'Transferência via TED efetuado com sucesso'
                        : 'Agendamento via TED efetuado com sucesso'
                    }
                  >
                    <div className="step3-content">
                      <div className="step3-content-infos">
                        <div className="step3-content-info">
                          <div className="info-item">
                            Valor
                            <span>
                              {priceFormatter.format(response.value * -1)}
                            </span>
                          </div>

                          <div className="info-item">
                            Data
                            <span>
                            {new Date(transferDateTime).toLocaleDateString('pt-BR')}
                            </span>
                          </div>
                        </div>

                        <div className="step3-content-info">
                          <div className="info-item">
                            Nome
                            <span>{response.toName}</span>
                          </div>

                          <div className="info-item">
                            CPJ/CNPJ
                            <span>{formatCnpjCpf(response.toTaxNumber)}</span>
                          </div>
                        </div>

                        <div className="step3-content-info">
                          <div className="info-item">
                            Instituição
                            <span>
                              {
                                banco_codigo.find(
                                  (item) => item.value === response.toBank
                                ).label
                              }
                            </span>
                          </div>

                          <div className="info-item">
                            Agência e Conta
                            <span>
                              {response.toBankBranch} |{' '}
                              {response.toBankAccount +
                                '-' +
                                response.toBankAccountDigit}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="step-buttons step3-buttons">
                        <NavLink
                          to="/comprovante"
                          target="_blank"
                          className="step-button"
                        >
                          Compartilhar comprovante
                          <FiShare2 size={16} />
                        </NavLink>
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            )}

            {/*  */}
          </div>
        </div>
      </div>
      <ToastContainer limit={2} />

      <ReactModal
        isOpen={isOpenModal}
        className={'change-modal'}
        overlayClassName={'overlay-pix-modal'}
      >
        <BoxModal close={() => setIsOpenModal(false)} maxWidth={600}>
          <div className="col-md-10">
            <p style={{ marginBottom: '15px' }}>
              Você receberá um código de confirmação via SMS para finalizar essa
              transação
            </p>
            <div className="input-area">
              <input
                style={{ borderRadius: '5px', padding: '3px' }}
                type="text"
                placeholder="Insira o código MFA"
                value={mfaCode}
                onChange={(e) => setMfaCode(e.target.value)}
              />
            </div>
            <FilterItemMfaGeneralTed>
              {isDisabled && (
                <p>
                  Você pode solicitar um novo
                  <br /> código em 0:
                  {countdown} segundos
                </p>
              )}
              <button onClick={() => handleClick()} disabled={isDisabled}>
                Novo Código
              </button>
            </FilterItemMfaGeneralTed>
            <div className="d-flex gap-3 mt-5">
              <EntryCancel
                className="btn-secondary col-md-5"
                onClick={() => setIsOpenModal(false)}
              >
                Cancelar
              </EntryCancel>
              <FilterButton
                className="btn-primary col-md-5"
                onClick={() => sendTransfers(mfaCode)}
              >
                Confirmar
              </FilterButton>
            </div>
          </div>
        </BoxModal>
      </ReactModal>
    </>
  );
}
